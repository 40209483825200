<template>
  <div :key="$route.params.objectid">
    <cbs-object
      v-if="objectFull"
      :objectid="objectid"
      :object-full="objectFull"
    />
    <cbs-data-object hidden :objectid="objectid" @load="onObjectLoad" />
  </div>
</template>

<script>
import CbsObject from '@/cubus/components/object/CbsObject.vue'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  components: {
    CbsObject,
    CbsDataObject,
  },
  props: {},
  data() {
    return {
      objectid: Number(this.$route.params.objectid),
      objectFull: null,
    }
  },
  created() {
    useCubus.setPageTitle(`Object ${this.objectid}`)
  },
  methods: {
    onObjectLoad(obj) {
      this.objectFull = obj
      if (obj && obj.object && obj.object.name) {
        useCubus.setPageTitle(obj.object.name)
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    // Обновляем данные при изменении параметра маршрута
    this.objectFull = null
    this.objectid = to.params.objectid
    // this.fetchUserData(this.userId);
    next() // Продолжить навигацию
  },
}
</script>

<style scoped>

</style>
